import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
import VotingGroup from './votingGroup';
const util = require('util');

export default class RegisterVoter extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            voterFirstName: '',
            voterMiddleName: '',
            voterLastName: '',
            voterEmail: '',
            voterCellPhone: '',
            voterAddress: '',
            voterBirthDay: '',
            votingGroup: '',
            votingGroups: [],
            precinct: '',
            countyName: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeVoterFirstName = this.onChangeVoterFirstName.bind(this);
        this.onChangeVoterMiddleName = this.onChangeVoterMiddleName.bind(this);
        this.onChangeVoterLastName = this.onChangeVoterLastName.bind(this);
        this.onChangeVoterEmail = this.onChangeVoterEmail.bind(this);
        this.onChangeVoterAddress = this.onChangeVoterAddress.bind(this);
        this.onChangeVoterBirthday = this.onChangeVoterBirthday.bind(this);
        this.onChangeVotingGroup = this.onChangeVotingGroup.bind(this);
        this.onChangeVoterPrecinct = this.onChangeVoterPrecinct.bind(this);
        this.onChangeVoterCounty = this.onChangeVoterCounty.bind(this);
    }

    componentDidMount() {
        trackPromise(axios.get('/votingGroup') 
            .then(response => {
                console.log(`RegisterVoter, votingGroup: ${response.data}`);
                this.setState({
                    votingGroups: response.data     // set to state to trigger rendering
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );

    }

    render()
    {
        console.log(`RegisterVoter.render, state: ${util.inspect(this.state)}`);
        if (this.state.votingGroups.length <= 0) {
            return (<div></div>);       // Don't trigger ctor of VotingGroup
        }
        return (
            <div>
                <h3>Register</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>First Name: </label>
                        <input className="form-control"
                            id="firstName"
                            value={this.state.voterFirstName}
                            onChange={this.onChangeVoterFirstName}
                            />
                    </div>
                    <div className="form-group">
                        <label>Middle Name: </label>
                        <input className="form-control"
                            id="middleName"
                            value={this.state.voterMiddleName}
                            onChange={this.onChangeVoterMiddleName}
                            />
                    </div>
                    <div className="form-group">
                        <label>Last Name: </label>
                        <input className="form-control"
                            id="lastName"
                            value={this.state.voterLastName}
                            onChange={this.onChangeVoterLastName}
                            />
                    </div>
                    <div className="form-group">
                        <label>Email: </label>
                        <input className="form-control"
                            id="email"
                            value={this.state.voterEmail}
                            onChange={this.onChangeVoterEmail}
                            />
                    </div>
                    <div className="form-group">
                        <label>Precinct: </label>
                        <input className="form-control"
                            id="precinct"
                            value={this.state.precinct}
                            onChange={this.onChangeVoterPrecinct}
                            />
                    </div>
                    <div className="form-group">
                        <label>County: </label>
                        <input className="form-control"
                            id="county"
                            value={this.state.countyName}
                            onChange={this.onChangeVoterCounty}
                            />
                    </div>
                    <VotingGroup votingGroups={this.state.votingGroups} votingGroup={this.state.votingGroup} callback={this.onChangeVotingGroup} />
                    <div className="form-group">
                        <label>Address: </label>
                        <input className="form-control"
                            id="address"
                            value={this.state.voterAddress}
                            onChange={this.onChangeVoterAddress}
                            />
                    </div>
                    <div className="form-group">
                        <label>Birthday (mm/dd/yyyy): </label>
                        <input className="form-control"
                            id="birthday"
                            value={this.state.voterBirthDay}
                            onChange={this.onChangeVoterBirthday}
                            />
                    </div>
                    <div className="form-group mt-4">
                        <input type="submit" value="Register Voter" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    onChangeVotingGroup(e)
    {
        this.setState({
            votingGroup: e.target.value
        });
    }

    onChangeVoterBirthday(e)
    {
        this.setState({
            voterBirthDay: e.target.value
        });
    }

    onChangeVoterAddress(e)
    {
        this.setState({
            voterAddress: e.target.value
        });
    }

    onChangeVoterEmail(e)
    {
        this.setState({
            voterEmail: e.target.value
        });
    }

    onChangeVoterLastName(e)
    {
        this.setState({
            voterLastName: e.target.value
        });
    }

    onChangeVoterMiddleName(e)
    {
        this.setState({
            voterMiddleName: e.target.value
        });
    }

    onChangeVoterFirstName(e)
    {
        this.setState({
            voterFirstName: e.target.value
        });
    }

    onChangeVoterPrecinct(e)
    {
        this.setState({
            precinct: e.target.value
        });
    }

    onChangeVoterCounty(e)
    {
        this.setState({
            countyName: e.target.value
        })
    }

    onSubmit(e)
    {
        e.preventDefault();

        let arr = this.state.voterBirthDay.split('/');
        let voterInfo = {
            voterFirstName: this.state.voterFirstName,
            voterMiddleName: this.state.voterMiddleName,
            voterLastName: this.state.voterLastName,
            voterEmail: this.state.voterEmail,
            voterCellPhone: this.state.voterCellPhone,
            voterAddress: this.state.voterAddress,
            birthMonth: arr[0],
            birthDate: arr[1],
            birthYear: arr[2],
            votingGroup: this.state.votingGroup,
            precinct: this.state.precinct,
            countyName: this.state.countyName
        };

        trackPromise(axios.post('/register', voterInfo)
            .then(res => {
                this.setState({
                    voterFirstName: '',
                    voterMiddleName: '',
                    voterLastName: '',
                    voterEmail: '',
                    voterCellPhone: '',
                    voterAddress: '',
                    voterBirthDay: ''
                });
            })
            .catch(error => {
                console.error('RegisterVoter.onSubmit, error: ' + util.inspect(error));
                notify(error);
            })
        );
    }
}