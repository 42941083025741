import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
import BallotSummary from './ballotSummary';


export default class ListBallots extends React.Component {
    constructor(props) {
        super(props);

        this.state = {ballots: []};
    }

    componentDidMount() {
        trackPromise(axios.get('/owner/all')
            .then(response => {
                this.setState({
                    ballots: response.data
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );
    }

    listBallots() {
        let removeBallot = this.removeBallot.bind(this);
        let activateBallot = this.activateBallot.bind(this);
        
        return this.state.ballots.map(function(currentBallot) {
            return (
                <BallotSummary 
                    ballot={currentBallot}
                    key={currentBallot.id}
                    removeBallotCallback={removeBallot}
                    activateBallotCallback={activateBallot}
                />
            );
        });
    }
    
    removeBallot(id) {
        this.setState(prevState => ({ ballots: prevState.ballots.filter(ballot => ballot.id !== id) }));
    }
    
    activateBallot(id) {
        // nothing for now, api needs to tell us if the ballot is activated, this way we change the state of the activate button
    }

    render() {
        return (
            <div>
                <h3>Ballots List</h3>
                <table className='table table-striped table-responsive' >
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>VotingGroup</th>
                            <th>Title</th>
                            <th>Language</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.listBallots() }
                    </tbody>
                </table>
            </div>                
        );
    }

}