import React from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
const util = require('util');

class RequestToVote extends React.Component
{
    constructor(props) {
        super(props);


        this.ballotId = props.match.params.ballotId;
        console.log(`RequestToVote.ctor, ballotId: ${this.ballotId}`);

        this.state = {
            title: '',
            voterEmail: '',
            submitted: false,
            message: ''
        };

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        trackPromise(axios.get('/ballot/' + this.ballotId)
            .then(response => {
                let ballot = response.data;
                this.setState({
                    title: ballot.title,
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );
    }

    render() {
        console.log(`RequestToVote.render, state: ${util.inspect(this.state)}`);
        if (this.state.submitted) {
            return (<div>{this.state.message}</div>);
        }

        return (
            <div>
                <h3>Opt-in the Poll: {this.state.title}</h3>
                <p>Please note that the results will be summarized for both registered voters and the general public.
                   If you are a registered voter, please enter your email below to make your voice heard.</p>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>Your email (please check your email after submit): </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.voterEmail}
                            onChange={this.onChangeEmail}
                            />
                    </div>
                    <div className="form-group">
                        <input type="submit" value="Submit" className="btn btn-primary"/>
                    </div>
                </form>
                <br/>
                <p>If you are not a registered voter or would vote as a general public, click the link below: </p>
                <Link to={`/vote/${this.ballotId}/anonymous`}>Vote as anonymous</Link>
            </div>
        );
    }

    onChangeEmail(e) {
        this.setState({
            voterEmail: e.target.value
        });
    }

    onSubmit(e) {
        e.preventDefault();

        const optInRequest = {
            ballotId: this.ballotId,
            voterEmail: this.state.voterEmail
        };
        console.log(`RequestToVote.onSubmit, optInRequest: ${util.inspect(optInRequest)}`);

        trackPromise(axios.post('/requestToVote', optInRequest)
                        .then(response => {
                            console.log(`RequestToVote.onSubmit, got response ${util.inspect(response)}`);
                            if (response.status === 200) {
                                this.setState({
                                    submitted: true,
                                    message: response.data
                                });
                            }
                        })
                        .catch(error => {
                            console.error(`RequestToVote.onSubmit, error: ${util.inspect(error)}`);
                            notify(error);
                        }));
    }
}

export default withRouter(RequestToVote);