import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
import VotingGroup from './votingGroup';
const util = require('util');

// Upload/Register a list of voters
export default class UploadVoters extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            votersList: [],
            votingGroup: '',
            votingGroups: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeVotingGroup = this.onChangeVotingGroup.bind(this);
        this.onChangeVotersList = this.onChangeVotersList.bind(this);
    }

    componentDidMount() {
        trackPromise(axios.get('/votingGroup') 
            .then(response => {
                console.log(`UploadVoters, votingGroup: ${response.data}`);
                this.setState({
                    votingGroups: response.data.votingGroups     // set to state to trigger rendering
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );

    }

    render()
    {
        console.log(`UploadVoters.render, state: ${util.inspect(this.state)}`);
        if (this.state.votingGroups.length <= 0) {
            return (<div></div>);       // Don't trigger ctor of VotingGroup
        }

        return (
            <div>
                <h3>Register Many Voters</h3>
                <form onSubmit={this.onSubmit}>
                    <VotingGroup votingGroups={this.state.votingGroups} votingGroup={this.state.votingGroup} callback={this.onChangeVotingGroup} />
                    <div className="form-group">
                        <label>List of voters in Json:</label>
                        <textarea className="form-control"
                            id="votersList"
                            value={this.state.votersList}
                            onChange={this.onChangeVotersList}
                            />
                    </div>
                    <div className="form-group mt-4">
                        <input type="submit" value="Register Voters" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    onChangeVotingGroup(e)
    {
        this.setState({
            votingGroup: e.target.value
        });
    }

    onChangeVotersList(e)
    {
        this.setState({
            votersList: e.target.value
        });
    }

    onSubmit(e)
    {
        e.preventDefault();

        let votersList = JSON.parse(this.state.votersList);
        console.log(`UploadVoters.onSubmit, votersList: ${util.inspect(votersList)}`);
        for (let i = 0; i < votersList.length; ++i) {
            votersList[i].votingGroup = this.state.votingGroup;
        }

        trackPromise(axios.post('/registerMany', votersList)
            .then(res => {
                this.setState({
                    votersList: [],
                    votingGroup: this.state.votingGroup
                });
            })
            .catch(error => {
                console.error('UploadVoters.onSubmit, error: ' + util.inspect(error));
                notify(error);
            })
        );
    }
}