import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
const util = require('util');

export default class AddEmailSender extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            appPass: '',
            tested: true
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeAppPass = this.onChangeAppPass.bind(this);
    }

    render()
    {
        console.log('AddEmailSender.render');
        return (
            <div>
                <h3>Add one email sender</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>Email: </label>
                        <input className="form-control"
                            id="email"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            />
                    </div>
                    <div className="form-group">
                        <label>App Password: </label>
                        <input className="form-control"
                            id="AppPass"
                            value={this.state.appPass}
                            onChange={this.onChangeAppPass}
                            />
                    </div>
                    <div className="form-group mt-4">
                        <input type="submit" value="Add Email Sender" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    onChangeEmail(e)
    {
        this.setState({
            email: e.target.value
        });
    }

    onChangeAppPass(e)
    {
        this.setState({
            appPass: e.target.value
        });
    }

    onSubmit(e)
    {
        e.preventDefault();

        let emailSenderInfo = {
            email: this.state.email,
            appPass: this.state.appPass,
            tested: true
        };

        trackPromise(axios.post('/emailSender', emailSenderInfo)
            .then(res => {
                this.setState({
                    email: '',
                    appPass: ''
                });
            })
            .catch(error => {
                console.error('AddEmailSender.onSubmit, error: ' + util.inspect(error));
                notify(error);
            })
        );
    }
}