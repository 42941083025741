import React from 'react';
import AuthorQuestionOptions from './authorQuestionOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes, faArrowDown, faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import util from 'util';

export default class AuthorBallotQuestion extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = props.question;
        this.key = props.index;
        this.removeQuestionCallback = props.removeQuestionCallback;
        this.moveQuestionUpCallback = props.moveQuestionUpCallback;
        this.moveQuestionDownCallback = props.moveQuestionDownCallback;
        this.updateQuestionCallback = props.updateQuestionCallback;
        console.log(`AuthorBallotQuestion`);

        this.onChangeStatement = this.onChangeStatement.bind(this);
        this.onChangeStatement0 = this.onChangeStatement0.bind(this);
        this.onChangeStatement1 = this.onChangeStatement1.bind(this);
        this.onChangeStatement2 = this.onChangeStatement2.bind(this);
        this.onChangeStatement3 = this.onChangeStatement3.bind(this);
        this.onChangeMostElectionNumber = this.onChangeMostElectionNumber.bind(this);
        this.onChangeWriteIn = this.onChangeWriteIn.bind(this);
        this.onChangeWriteInPrompt = this.onChangeWriteInPrompt.bind(this);
        this.removeQuestion = this.removeQuestion.bind(this);
        this.moveQuestionUp = this.moveQuestionUp.bind(this);
        this.moveQuestionDown = this.moveQuestionDown.bind(this);
        this.updateOptions = this.updateOptions.bind(this);
    }

    render()
    {
        console.log(`AuthorBallotQuestion::render, ${util.inspect(this.state)}`);
        let statementID = "Statement_" + this.key;
        let mostElectionNumberID = "MostElection_" + this.key;
        let writeInID = "WriteIn_" + this.key;
        let writeInPromptID = "WriteInPrompt_" + this.key;

        return (
            <div className="form-group position-relative">
                <h5>Question {this.key + 1}</h5>
                <span className="position-absolute" style={{top: "0", right: "0"}}>
                    <FontAwesomeIcon className="text-danger hand" title="Move question up" icon = {faArrowUp} onClick={this.moveQuestionUp}/>
                    &nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon className="text-danger hand" title="Move question down" icon = {faArrowDown} onClick={this.moveQuestionDown}/>
                    &nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon className="text-danger hand" title="Remove question" icon={faTimes} onClick={this.removeQuestion}/>
                </span>
                <div className="form-group">
                    <label>Statement (Format: statement1, anchorText, link, statement2): </label>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <input type="text"
                                    id={statementID+"_0"}
                                    className="form-control"
                                    value={this.state.statementWithLink[0]}
                                    onChange={this.onChangeStatement0}
                                    />
                            </td>
                            <td>
                                <input type="text"
                                    id={statementID+"_1"}
                                    className="form-control"
                                    value={this.state.statementWithLink[1]}
                                    onChange={this.onChangeStatement1}
                                    />
                            </td>
                            <td>
                                <input type="text"
                                    id={statementID+"_2"}
                                    className="form-control"
                                    value={this.state.statementWithLink[2]}
                                    onChange={this.onChangeStatement2}
                                    />
                            </td>
                            <td>
                                <input type="text"
                                    id={statementID+"_3"}
                                    className="form-control"
                                    value={this.state.statementWithLink[3]}
                                    onChange={this.onChangeStatement3}
                                    />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="form-group">
                    <label>The most number of selections: </label>
                    <input type="text"
                        id={mostElectionNumberID}
                        className="form-control"
                        value={this.state.mostElectionNumber}
                        onChange={this.onChangeMostElectionNumber}
                        />
                </div>
                <div className="form-group">
                    <label>Number of write-ins: </label>
                    <input type="text"
                        id={writeInID}
                        className="form-control"
                        value={this.state.writeIn}
                        onChange={this.onChangeWriteIn}
                        />
                </div>
                <div className="form-group">
                    <label>Write-in Prompt: </label>
                    <input type="text"
                        id={writeInPromptID}
                        className="form-control"
                        value={this.state.writeInPrompt}
                        onChange={this.onChangeWriteInPrompt}
                        />
                </div>
                <AuthorQuestionOptions options={this.state.options} updateOptionsCallback={this.updateOptions} />
                <hr />
            </div>
        );
    }

    removeQuestion()
    {
        this.removeQuestionCallback(this.key);
    }

    moveQuestionUp()
    {
        this.moveQuestionUpCallback(this.key);
    }

    moveQuestionDown()
    {
        this.moveQuestionDownCallback(this.key);
    }

    updateOptions(updatedOptions)
    {
        this.setState({
            options: updatedOptions
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeWriteIn(e)
    {
        this.setState({
            writeIn: e.target.value
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeWriteInPrompt(e)
    {
        this.setState({
            writeInPrompt: e.target.value
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeMostElectionNumber(e)
    {
        this.setState({
            mostElectionNumber: e.target.value
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeStatement(e)
    {
        this.setState({
            statement: e.target.value
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeStatement0(e)
    {
        let statementWithLinkTmp = this.state.statementWithLink;
        statementWithLinkTmp[0] = e.target.value;
        console.log("onChangeStatement0, statementWithLinkTmp: " + util.inspect(statementWithLinkTmp));
        this.setState({
            statementWithLink: statementWithLinkTmp
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeStatement1(e)
    {
        let statementWithLinkTmp = this.state.statementWithLink;
        statementWithLinkTmp[1] = e.target.value;
        console.log("onChangeStatement1, statementWithLinkTmp: " + util.inspect(statementWithLinkTmp));
        this.setState({
            statementWithLink: statementWithLinkTmp
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeStatement2(e)
    {
        let statementWithLinkTmp = this.state.statementWithLink;
        statementWithLinkTmp[2] = e.target.value;
        console.log("onChangeStatement2, statementWithLinkTmp: " + util.inspect(statementWithLinkTmp));
        this.setState({
            statementWithLink: statementWithLinkTmp
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }

    onChangeStatement3(e)
    {
        let statementWithLinkTmp = this.state.statementWithLink;
        statementWithLinkTmp[3] = e.target.value;
        console.log("onChangeStatement3, statementWithLinkTmp: " + util.inspect(statementWithLinkTmp));
        this.setState({
            statementWithLink: statementWithLinkTmp
        },
        () => this.updateQuestionCallback(this.key, this.state));
    }
}