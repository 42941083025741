import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';

function containsObject(obj, list) {
    var x;
    for (x in list) {
        if (list.hasOwnProperty(x) && list[x] === obj) {
            return true;
        }
    }

    return false;
}

class SingleSelectionQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.question = props.question;
        this.index = props.index;
        this.writeIn = props.writeIn;
        this.selection = props.selection;
        this.optionName = 'Question' + this.index;

        this.questionCallback = props.questionCallback;
        this.onChangeWriteIn = this.onChangeWriteIn.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
    }

    render() {
        return (
            <div className="form-group">
                <label>{this.question.statementWithLink[0]}<a href={this.question.statementWithLink[2]} target="_blank" rel="noopener noreferrer">{this.question.statementWithLink[1]}</a>{this.question.statementWithLink[3]}</label>
                <table className='table table-striped' style={{marginTop: 20}}>
                    {this.renderOptions()}
                    {this.renderWriteIn()}
                </table>
            </div>
        );
    }

    renderOptions() {
        let selection = this.selection;
        let optionName = this.optionName;
        return this.question.options.map(function(currentOption, i) {
            let optionChecked = (selection === currentOption);
            return (
                <tr key={i}>
                    <td>
                        <input type="radio" name={optionName} id={optionName + ',' + currentOption} value={currentOption} checked={optionChecked}
                            readOnly="readonly" />
                    </td>
                    <td>
                        <label>{currentOption}</label>
                    </td>
                </tr>
            );
        });
    }

    renderWriteIn() {
        if (this.question.writeIn !== 1) {
            return (
                <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
            );
        }

        let writeInValue = 'WriteIn';
        let optionChecked = (this.selection === writeInValue)
        return (
            <tr>
                <td>
                    <input type="radio" name={this.optionName} id={this.optionName + ',writeIn'} value={writeInValue} checked={optionChecked}
                        readOnly="readonly" />
                </td>
                <td>
                    <input type="text" className="form-control" value={this.writeIn}  readOnly="readonly" />
                </td>
            </tr>
        );
    }

    onChangeWriteIn(e) {
        this.writeIn = e.target.value;

        this.questionCallback(this.index, this.question.statement, [this.selection], [this.writeIn]);
    }

    onChangeRadio(e) {
        this.selection = e.target.value;

        this.questionCallback(this.index, this.question.statement, [this.selection], [this.writeIn]);
    }
}

class MultiWriteIn extends React.Component {
    constructor(props) {
        super(props);

        this.index = props.index;
        this.optionName = props.optionName;
        this.selectionName = 'writeIn' + this.index;
        this.writeIn = props.writeIn;
        this.selections = props.selections;

        this.selected = containsObject(this.selectionName, this.selections);

        this.onChangeCheckboxCallback = props.onChangeCheckboxCallback;
        this.onChangeWriteInCallback = props.onChangeWriteInCallback;
        this.onChangeWriteIn = this.onChangeWriteIn.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    render() {
        return (
            <tr>
                <td>
                    <input type="checkbox" name={this.optionName} id={this.optionName + ',' + this.selectionName} value={this.selectionName} checked={this.selected}
                        readOnly="readonly" />
                </td>
                <td>
                    <input type="text" className="form-control" value={this.writeIn} readOnly="readonly" />
                </td>
            </tr>
        );
    }

    onChangeWriteIn(e) {
        this.writeIn = e.target.value;

        this.onChangeWriteInCallback(this.index, this.writeIn);
    }

    onChangeCheckbox(e) {
        this.selected = !this.selected;
        this.onChangeCheckboxCallback(e);
    }
}

class MultiSelectionQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.question = props.question;
        this.index = props.index;
        this.selections = props.selections;
        this.writeIns = props.writeIns;
        this.optionName = 'Question' + this.index;
        this.maxSelection = props.maxSelection;
        this.maxWriteIn = props.maxWriteIn;

        if (this.selections === undefined) {
            this.selections = [];
        }

        if (this.writeIns === undefined) {
            this.writeIns = [];
            for (let i = 0; i < this.maxWriteIn; i++) {
                this.writeIns.push('');
            }
        }

        this.questionCallback = props.questionCallback;
        this.OnChangeCheckbox = this.OnChangeCheckbox.bind(this);
        this.onChangeWriteInCallback = this.onChangeWriteInCallback.bind(this);
    }

    render() {
        return (
            <div className='form-group'>
                <label>{this.question.statementWithLink[0]}<a href={this.question.statementWithLink[2]} target="_blank" rel="noopener noreferrer">{this.question.statementWithLink[1]}</a>{this.question.statementWithLink[3]}</label>
                <table className='table table-striped' style={{marginTop: 20}}>
                    {this.renderOptions()}
                    {this.renderWriteIns()}
                </table>
            </div>
        );
    }

    renderOptions() {
        let selections = this.selections;
        let optionName = this.optionName;
        return this.question.options.map(function(currentOption, i) {
            let optionChecked = false;
            if (selections !== undefined) {
                optionChecked = containsObject(currentOption, selections);
            }

            return (
                <tr key={i}>
                    <td>
                        <input type="checkbox" name={optionName} id={optionName + ',' + currentOption} value={currentOption} checked={optionChecked}
                            readOnly="readonly" />
                    </td>
                    <td>
                        <label>{currentOption}</label>
                    </td>
                </tr>
            );
        });
    }

    renderWriteIns() {
        let selections = this.selections;
        let optionName = this.optionName;
        let onChangeCheckbox = this.OnChangeCheckbox;
        let onChangeWriteInCallback = this.onChangeWriteInCallback;
        let dummyOnChangeWriteIn = this.dummyOnChangeWriteIn;
        return this.writeIns.map(function(currentWriteIn, i) {
            return (
                <MultiWriteIn key={i}
                    index={i}
                    optionName={optionName}
                    writeIn={currentWriteIn}
                    selections={selections}
                    onChangeCheckboxCallback={onChangeCheckbox}
                    onChangeWriteInCallback={onChangeWriteInCallback}
                    onChange={dummyOnChangeWriteIn}
                />
            );
        });
    }

    OnChangeCheckbox(e) {
        let value = e.target.value;
        if (containsObject(value, this.selections)) {
            this.selections = this.selections.filter(function(item) {
                return item !== value;
            });
        }
        else {
            if (this.selections.length < this.maxSelection) {
                this.selections.push(value);
            }
        }
        this.questionCallback(this.index, this.question.statement, this.selections, this.writeIns);
    }

    onChangeWriteInCallback(index, writeIn) {
        this.writeIns[index] = writeIn;

        this.questionCallback(this.index, this.question.statement, this.selections, this.writeIns);
    }

    dummyOnChangeWriteIn(e) {
        // Do nothing
    }
}

class VerifyVote extends React.Component
{
    constructor(props) {
        super(props);

        this.ballotId = props.match.params.ballotId;
        this.verificationSubmited = false;
        this.state = {
            errorMessage: '',
            ballot: {
                questions: []
            },
            elections: [],
            verificationCode: ''
        };
        this.renderBallot = this.renderBallot.bind(this);
        this.onSubmitVerification = this.onSubmitVerification.bind(this);
        this.onChangeVerification = this.onChangeVerification.bind(this);
        this.renderQuestions = this.renderQuestions.bind(this);
        this.questionCallback = this.questionCallback.bind(this);
        this.onSubmitBallot = this.onSubmitBallot.bind(this);
        this.renderVerification = this.renderVerification.bind(this);
    }

    render() {
        if (!this.verificationSubmited) {
            return this.renderVerification();
        }
        else {
            return this.renderBallot();
        }
    }

    renderVerification() {
        return (
            <div>
                <h3>Verify your vote</h3>
                <form onSubmit={this.onSubmitVerification}>
                    <div className="form-group">
                        <label>Verification Code: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.verificationCode}
                            onChange={this.onChangeVerification}
                            />
                    </div>
                    <div className="form-group mt-4">
                        <input type="submit" value="Verify" className="btn btn-primary" />
                    </div>
                </form>
                <h5>{this.state.errorMessage}</h5>
            </div>
        );
    }

    onChangeVerification(e) {
        this.setState({
            verificationCode: e.target.value
        });
    }

    onSubmitVerification(e) {
        e.preventDefault();

        trackPromise(axios.get('/verify/' + this.state.verificationCode)
            .then(response => {
                if (response.status === 200) {
                    let ballotAndElection = response.data;
                    this.verificationSubmited = true;
                    this.setState({
                        ballot: ballotAndElection.ballot,
                        elections: JSON.parse(ballotAndElection.elections)
                    });
                }
            })
            .catch(error => {
                console.error('receive ballot error: ' + error);
                this.errorMessage = error;
                notify(error)
            })
        );
    }

    renderBallot() {
        return (
            <div>
                <h6>Record for verification code: {this.state.verificationCode}</h6>
                <h3>{this.state.ballot.title}</h3>
                <h6>From {this.state.ballot.startDate} to {this.state.ballot.endDate}</h6>
                <form onSubmit={this.onSubmitBallot}>
                    {this.renderQuestions()}
                    <div className="form-group">
                        <input type="submit" value="Submit Ballot" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    renderQuestions() {
        let questionCallback = this.questionCallback;
        let elections = this.state.elections;
        return this.state.ballot.questions.map(function(currentQuestion, i) {
            if (currentQuestion.mostElectionNumber <= 1) {
                let currentWriteIn = '';
                if ('writeIns' in elections[i]) {
                    currentWriteIn = elections[i].writeIns[0];
                }
                let currentSelection = '';
                if ('selections' in elections[i]) {
                    currentSelection = elections[i].selections[0];
                }

                return (
                    <SingleSelectionQuestion key={i} index={i} question={currentQuestion} writeIn={currentWriteIn} selection={currentSelection}
                        questionCallback={questionCallback} />
                );
            }
            return (
                <MultiSelectionQuestion key={i} index={i}
                    question={currentQuestion} writeIns={elections[i].writeIns} 
                    selections={elections[i].selections}
                    maxSelection={currentQuestion.mostElectionNumber}
                    maxWriteIn={currentQuestion.writeIn}
                    questionCallback={questionCallback} />
            )
        });
    }

    questionCallback(index, statement, selections, writeIns) {
        let modifiedElections = this.state.elections;
        let election = modifiedElections[index];
        election.statement = statement;
        election.selections = selections;
        election.writeIns = writeIns;
        this.setState({
            elections: modifiedElections
        });
    }

    onSubmitBallot() {}
}

export default withRouter(VerifyVote);