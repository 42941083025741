import React from 'react';
import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
const util = require('util');

var MaxWriteInLength = 512;

function containsObject(obj, list) {
    var x;
    for (x in list) {
        if (list.hasOwnProperty(x) && list[x] === obj) {
            return true;
        }
    }

    return false;
}

class SingleSelectionQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.question = props.question;
        this.index = props.index;
        this.writeIn = props.writeIn;
        this.writeInPrompt = props.writeInPrompt;
        this.selection = props.selection;
        this.mostElectionNumber = props.mostElectionNumber;
        this.optionName = 'Question' + this.index;

        this.questionCallback = props.questionCallback;
        this.onChangeWriteIn = this.onChangeWriteIn.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.renderTable = this.renderTable.bind(this);
    }

    render() {
        return (
            <div className="form-group">
                <h5>
                    <label>{this.question.statementWithLink[0]}<a href={this.question.statementWithLink[2]} target="_blank" rel="noopener noreferrer">{this.question.statementWithLink[1]}</a>{this.question.statementWithLink[3]}</label>
                </h5>
                {this.renderTable()}
            </div>
        );
    }

    renderTable() {
        if (this.mostElectionNumber > 0 || this.question.options.length > 0) {
            return (
                <table className='table table-striped' style={{marginTop: 20}}>
                    <colgroup>
                        <col span="1" style={{width: "5%"}}/>
                        <col span="1" style={{width: "18%"}}/>
                        <col span="1" style={{width: "77%"}}/>
                    </colgroup>
                    <tbody>
                        {this.renderOptions()}
                        {this.renderWriteIn()}
                    </tbody>
                </table>
            );
        }
        else {
            return (<div/>);
        }
    }

    renderOptions() {
        let selection = this.selection;
        let optionName = this.optionName;
        let onChangeRadio = this.onChangeRadio;
        return this.question.options.map(function(currentOption, i) {
            let optionChecked = (selection === currentOption);
            return (
                <tr key={i}>
                    <td>
                        <input type="radio" name={optionName} id={optionName + ',' + currentOption} value={currentOption} checked={optionChecked}
                            onChange={onChangeRadio} />
                    </td>
                    <td colSpan={2}>
                        {currentOption}
                    </td>
                </tr>
            );
        });
    }

    renderWriteIn() {
        if (this.question.writeIn !== 1) {
            return (
                <tr><td>&nbsp;</td><td colSpan={2}>&nbsp;</td></tr>
            );
        }

        let writeInValue = 'WriteIn';
        let optionChecked = (this.selection === writeInValue)
        return (
            <tr>
                <td>
                    <input type="radio" name={this.optionName} id={this.optionName + ',writeIn'} value={writeInValue} checked={optionChecked}
                        onChange={this.onChangeRadio}/>
                </td>
                <td>
                    <label>{this.writeInPrompt} (up to {MaxWriteInLength} characters): </label>
                </td>
                <td>
                    <input type="text" className="form-control" value={this.writeIn} onChange={this.onChangeWriteIn} />
                </td>
            </tr>
        );
    }

    onChangeWriteIn(e) {
        this.writeIn = e.target.value;
        if (MaxWriteInLength < this.writeIn.length) {
            this.writeIn = this.writeIn.slice(0, MaxWriteInLength);
        }

        this.questionCallback(this.index, this.question.statement, [this.selection], [this.writeIn]);
    }

    onChangeRadio(e) {
        this.selection = e.target.value;

        this.questionCallback(this.index, this.question.statement, [this.selection], [this.writeIn]);
    }
}

class MultiWriteIn extends React.Component {
    constructor(props) {
        super(props);

        this.index = props.index;
        this.optionName = props.optionName;
        this.selectionName = 'writeIn' + this.index;
        this.writeIn = '';
        this.writeInPrompt = props.writeInPrompt;

        this.selected = false;

        this.onChangeCheckboxCallback = props.onChangeCheckboxCallback;
        this.onChangeWriteInCallback = props.onChangeWriteInCallback;
        this.onChangeWriteIn = this.onChangeWriteIn.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    render() {
        return (
            <tr>
                <td>
                    <input type="checkbox" name={this.optionName} id={this.optionName + ',' + this.selectionName} value={this.selectionName} defaultChecked={this.selected}
                        onChange={this.onChangeCheckbox} />
                </td>
                <td>
                    <label>{this.writeInPrompt} (up to {MaxWriteInLength} characters): </label>
                </td>
                <td>
                    <input type="text" className="form-control" value={this.writeIn} onChange={this.onChangeWriteIn} />
                </td>
            </tr>
        );
    }

    onChangeWriteIn(e) {
        this.writeIn = e.target.value;
        if (MaxWriteInLength < this.writeIn.length) {
            this.writeIn = this.writeIn.slice(0, MaxWriteInLength);
        }

        this.onChangeWriteInCallback(this.index, this.writeIn);
    }

    onChangeCheckbox(e) {
        this.selected = !this.selected;
        this.onChangeCheckboxCallback(e);
    }
}

class MultiSelectionQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.question = props.question;
        this.index = props.index;
        this.selections = props.selections;
        this.writeIns = props.writeIns;
        this.optionName = 'Question' + this.index;
        this.maxSelection = props.maxSelection;
        this.maxWriteIn = props.maxWriteIn;
        this.writeInPrompt = props.writeInPrompt;

        if (this.selections === undefined) {
            this.selections = [];
        }

        if (this.writeIns === undefined) {
            this.writeIns = [];
            for (let i = 0; i < this.maxWriteIn; i++) {
                this.writeIns.push('');
            }
        }

        this.questionCallback = props.questionCallback;
        this.OnChangeCheckbox = this.OnChangeCheckbox.bind(this);
        this.onChangeWriteInCallback = this.onChangeWriteInCallback.bind(this);
        this.renderTable = this.renderTable.bind(this);
    }

    render() {
        return (
            <div className='form-group'>
                <h5>
                    <label>{this.question.statementWithLink[0]}<a href={this.question.statementWithLink[2]} target="_blank" rel="noopener noreferrer">{this.question.statementWithLink[1]}</a>{this.question.statementWithLink[3]}</label>
                </h5>
                {this.renderTable()}
            </div>
        );
    }

    renderTable() {
        if (this.maxSelection > 0 || this.question.options.length > 0) {
            return (
                <table className='table table-striped' style={{marginTop: 20}}>
                    <colgroup>
                        <col span="1" style={{width: "5%"}}/>
                        <col span="1" style={{width: "18%"}}/>
                        <col span="1" style={{width: "77%"}}/>
                    </colgroup>
                    {this.renderOptions()}
                    {this.renderWriteIns()}
                </table>
            );
        }
        else {
            return (<div/>);
        }
    }

    renderOptions() {
        let selections = this.selections;
        let optionName = this.optionName;
        let onChangeCheckbox = this.OnChangeCheckbox;
        return this.question.options.map(function(currentOption, i) {
            let optionChecked = false;
            if (selections !== undefined) {
                optionChecked = containsObject(currentOption, selections);
            }

            return (
                <tr key={i}>
                    <td>
                        <input type="checkbox" name={optionName} id={optionName + ',' + currentOption} value={currentOption} checked={optionChecked}
                            onChange={onChangeCheckbox} />
                    </td>
                    <td colSpan={2}>
                        <label>{currentOption}</label>
                    </td>
                </tr>
            );
        });
    }

    renderWriteIns() {
        let optionName = this.optionName;
        let writeInPrompt = this.writeInPrompt;
        let onChangeCheckbox = this.OnChangeCheckbox;
        let onChangeWriteInCallback = this.onChangeWriteInCallback;
        let dummyOnChangeWriteIn = this.dummyOnChangeWriteIn;
        return this.writeIns.map(function(currentWriteIn, i) {
            return (
                <MultiWriteIn key={i}
                    index={i}
                    optionName={optionName}
                    writeInPrompt={writeInPrompt}
                    onChangeCheckboxCallback={onChangeCheckbox}
                    onChangeWriteInCallback={onChangeWriteInCallback}
                    onChange={dummyOnChangeWriteIn}
                />
            );
        });
    }

    OnChangeCheckbox(e) {
        let value = e.target.value;
        if (containsObject(value, this.selections)) {
            this.selections = this.selections.filter(function(item) {
                return item !== value;
            });
        }
        else {
            if (this.selections.length < this.maxSelection) {
                this.selections.push(value);
            }
        }

        this.questionCallback(this.index, this.question.statement, this.selections, this.writeIns);
    }

    onChangeWriteInCallback(index, writeIn) {
        this.writeIns[index] = writeIn;

        this.questionCallback(this.index, this.question.statement, this.selections, this.writeIns);
    }

    dummyOnChangeWriteIn(e) {
        // Do nothing
    }
}

class Vote extends React.Component
{
    constructor(props) {
        super(props);
        this.ballotId = props.match.params.ballotId;
        this.votingCode = props.match.params.votingCode;
        this.sentCode = false;
        this.authorized = false;
        this.ballotSubmited = false;
        this.showVerification = false;

        const cookieJar = new CookieJar();
        this.axios = wrapper(axios.create({ cookieJar }));

        this.state = {
            votingCode: this.votingCode,
            pin: '',
            birthDay: '',
            errorMessage: '',
            ballot: {
                questions: []
            },
            elections: [],
            verificationCode: ''
        };

        this.renderVotingCode = this.renderVotingCode.bind(this);
        this.onSubmitVotingCode = this.onSubmitVotingCode.bind(this);
        this.rednerPinAuthorization = this.rednerPinAuthorization.bind(this);
        this.onChangePin = this.onChangePin.bind(this);
        this.onSubmitPinAuthorization = this.onSubmitPinAuthorization.bind(this);
        this.renderAuthorization = this.renderAuthorization.bind(this);
        this.renderBallot = this.renderBallot.bind(this);
        this.onSubmitAuthorization = this.onSubmitAuthorization.bind(this);
        this.onChangeVotingCode = this.onChangeVotingCode.bind(this);
        this.onChangeBirthDay = this.onChangeBirthDay.bind(this);
        this.renderQuestions = this.renderQuestions.bind(this);
        this.questionCallback = this.questionCallback.bind(this);
        this.onSubmitBallot = this.onSubmitBallot.bind(this);
        this.renderVerification = this.renderVerification.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        if (!this.votingCode) {
            return;
        }

        let voterInfo = {
            ballotId: this.ballotId,
            voteCode: this.state.votingCode,
            pin: '123456'
        };

        trackPromise(this.axios.post('/verifyPinAndGetBallot', voterInfo)
            .then(response => {
                if (response.status === 200) {
                    this.authorized = true;
                    this.errorMessage = '';
                    let emptyElections = [];
                    response.data.questions.forEach(element => {
                        emptyElections.push({});
                    });
                    this.sentCode = true;
                    this.authorized = true;
                    this.setState({
                        ballot: response.data,
                        elections: emptyElections,
                        writeIns: [],
                        errorMessage: ''
                    });
                    this.showVerification = response.data.showVerification ? true : false;
                    console.log(`Vote.componentDidMount, showVerification: ${this.showVerification}`);
                }
                else {
                }
            })
            .catch(error => {
                console.error("Vote.onSubmitPinAuthorization, receive ballot error: " + util.inspect(error));
                notify(error);
                this.setState({
                    errorMessage: error.response.data
                });
                this.props.history.push('/optin/' + this.ballotId);
            })
        );
        /*
        const votingInfo = {
            voteCode: this.votingCode,
            ballotId: this.ballotId
        };

        trackPromise(this.axios.post('/triggerAuthentication', votingInfo) 
            .then(response => {
                if (response.status === 200) {
                    this.sentCode = true;
                    this.setState({
                        pin: '',
                        errorMessage: ''
                    });
                }
            })
            .catch(error => {
                notify(error);
                this.setState({
                    errorMessage: error.response.data
                });
            })
        );
        */
    }

    render() {
        if (!this.sentCode) {
            return this.renderVotingCode();
        }
        else if (!this.authorized) {
            //return this.renderAuthorization();
            return this.rednerPinAuthorization();
        }
        else if (!this.ballotSubmited) {
            return this.renderBallot();
        }
        else {
            return this.renderVerification();
        }
    }

    renderVotingCode() {
        return (
            <div>
                <h3>Authorization</h3>
                <form onSubmit={this.onSubmitVotingCode}>
                    <div className="form-group">
                        <label>Voting Code: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.votingCode}
                            onChange={this.onChangeVotingCode}
                            />
                    </div>
                    {
                        this.state.errorMessage && <div><label>{this.state.errorMessage}</label></div>
                    }
                    <div className="form-group mt-2">
                        <input type="submit" value="Submit" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    onSubmitVotingCode(e) {
        e.preventDefault();

        const votingInfo = {
            voteCode: this.state.votingCode,
            ballotId: this.ballotId
        };

        trackPromise(this.axios.post('/triggerAuthentication', votingInfo) 
            .then(response => {
                if (response.status === 200) {
                    this.sentCode = true;
                    this.setState({
                        pin: '',
                        errorMessage: ''
                    });
                }
            })
            .catch(error => {
                notify(error);
                this.setState({
                    errorMessage: error.response.data
                });
            })
        );
    }

    rednerPinAuthorization() {
        return (
            <div>
                <h3>Authorization</h3>
                <form onSubmit={this.onSubmitPinAuthorization}>
                    <div className="form-group">
                        <label>Voting Code: {this.state.votingCode}</label>
                    </div>
                    <div className="form-group my-2">
                        <label>Pin (Please check your email): </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.pin}
                            onChange={this.onChangePin}
                            />
                    </div>
                    {
                        this.state.errorMessage && <div><label>{this.state.errorMessage}</label></div>
                    }
                    <div className="form-group mt-2">
                        <input type="submit" value="Verify" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    onSubmitPinAuthorization(e) {
        e.preventDefault();

        let voterInfo = {
            ballotId: this.ballotId,
            voteCode: this.state.votingCode,
            pin: this.state.pin
        };

        trackPromise(this.axios.post('/verifyPinAndGetBallot', voterInfo)
            .then(response => {
                if (response.status === 200) {
                    this.authorized = true;
                    this.errorMessage = '';
                    let emptyElections = [];
                    response.data.questions.forEach(element => {
                        emptyElections.push({});
                    });
                    this.setState({
                        ballot: response.data,
                        elections: emptyElections,
                        writeIns: [],
                        errorMessage: ''
                    });
                }
                else {
                }
            })
            .catch(error => {
                console.error("Vote.onSubmitPinAuthorization, receive ballot error: " + util.inspect(error));
                notify(error);
                this.setState({
                    errorMessage: error.response.data
                });
            })
        );
    }

    renderAuthorization() {
        return (
            <div>
                <h3>Authorization</h3>
                <form onSubmit={this.onSubmitAuthorization}>
                    <div className="form-group">
                        <label>Voting Code: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.votingCode}
                            onChange={this.onChangeVotingCode}
                            />
                    </div>
                    <div className="form-group">
                        <label>Birthday (mm/dd/yyyy)</label>
                        <input type="date"
                            className="form-control"
                            value={this.state.birthDay}
                            onChange={this.onChangeBirthDay}
                            />
                    </div>
                    <div className="form-group my-2">
                        <input type="submit" value="Verify" className="btn btn-primary" />
                    </div>
                </form>
                <h5>{this.state.errorMessage}</h5>
            </div>
        );
    }

    onChangePin(e) {
        this.setState({
            pin: e.target.value
        });
    }

    onChangeBirthDay(e) {
        this.setState({
            birthDay: e.target.value
        });
    }

    onChangeVotingCode(e) {
        this.setState({
            votingCode: e.target.value
        });
    }

    onSubmitAuthorization(e) {
        e.preventDefault();

        let arr = this.state.birthDay.split('-');
        let voter = {
            ballotId: this.ballotId,
            voteCode: this.state.votingCode,
            birthYear: arr[0],
            birthMonth: arr[1],
            birthDate: arr[2]
        };

        trackPromise(this.axios.post('/verifyAndGetBallot', voter)
            .then(response => {
                if (response.status === 200) {
                    this.authorized = true;
                    this.errorMessage = '';
                    let emptyElections = [];
                    response.data.questions.forEach(element => {
                        emptyElections.push({});
                    });
                    this.setState({
                        ballot: response.data,
                        elections: emptyElections,
                        writeIns: []
                    });
                }
            })
            .catch(error => {
                notify(error);
                this.errorMessage = error;
            })
        );
    }

    renderBallot() {
        return (
            <div>
                <h3>{this.state.ballot.title}</h3>
                <h6>From {this.state.ballot.startDate} to {this.state.ballot.endDate}</h6>
                <form onSubmit={this.onSubmitBallot}>
                    {this.renderQuestions()}
                    <div className="form-group">
                        <input type="submit" value="Submit" className="btn btn-primary" />
                    </div>
                </form>
                <p></p>
                <p>{this.state.ballot.disclaimer[0]}<a href={this.state.ballot.disclaimer[2]} target="_blank" rel="noopener noreferrer">{this.state.ballot.disclaimer[1]}</a>{this.state.ballot.disclaimer[3]}</p>
                <p></p>
            </div>
        );
    }

    renderQuestions() {
        let questionCallback = this.questionCallback;
        let elections = this.state.elections;
        return this.state.ballot.questions.map(function(currentQuestion, i) {
            if (currentQuestion.mostElectionNumber == 1) {
                let currentWriteIn = '';
                if ('writeIns' in elections[i]) {
                    currentWriteIn = elections[i].writeIns[0];
                }
                let currentSelection = '';
                if ('selections' in elections[i]) {
                    currentSelection = elections[i].selections[0];
                }

                return (
                    <SingleSelectionQuestion key={i} index={i} question={currentQuestion} writeIn={currentWriteIn} selection={currentSelection}
                        questionCallback={questionCallback} mostElectionNumber={currentQuestion.mostElectionNumber}
                        writeInPrompt={currentQuestion.writeInPrompt}/>
                );
            }
            return (
                <MultiSelectionQuestion key={i} index={i}
                    question={currentQuestion} writeIns={elections[i].writeIns} 
                    selections={elections[i].selections}
                    maxSelection={currentQuestion.mostElectionNumber}
                    maxWriteIn={currentQuestion.writeIn}
                    writeInPrompt={currentQuestion.writeInPrompt}
                    questionCallback={questionCallback} />
            )
        });
    }

    questionCallback(index, statement, selections, writeIns) {
        let modifiedElections = this.state.elections;
        let election = modifiedElections[index];
        election.statement = statement;
        election.selections = selections;
        election.writeIns = writeIns;
        this.setState({
            elections: modifiedElections
        });
    }

    onSubmitBallot(e) {
        e.preventDefault();

        let arr = this.state.birthDay.split('-');
        let voter = {
            ballotId: this.ballotId,
            voteCode: this.state.votingCode,
            birthYear: arr[0],
            birthMonth: arr[1],
            birthDate: arr[2]
        };

        let ballotResult = {
            voter: voter,
            elections: JSON.stringify(this.state.elections)
        };

        trackPromise(this.axios.post('/voteResult', ballotResult)
            .then(response => {
                let responseData = response.data;
                this.ballotSubmited = true;
                this.setState({
                    verificationCode: responseData.verificationCode
                });
            })
            .catch(error => {
                notify(error);
                console.error('Vote.onSubmitBallot, error: ' + util.inspect(error));
            })
        );
    }

    renderVerification() {
        if (this.showVerification)
        {
            return (
                <div>
                    <h6>Thank you for participating in this survey.</h6>
                    <p>Please save your Verification Code for future query: {this.state.verificationCode}.</p>
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <h6>Thank you for participating in this survey.</h6>
                </div>
            );
        }
    }
}

export default withRouter(Vote);