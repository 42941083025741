import React from 'react';
const util = require('util');

// Shared: VotingGroup selection
export default class VotingGroup extends React.Component {
    constructor(props) {
        super(props);

        this.votingGroups = props.votingGroups;
        this.votingGroup = props.votingGroup;   // votingGroup selected
        this.callback = props.callback;

        console.log(`VotingGroup.ctor, votingGroup: ${this.votingGroup}, votingGroups: ${util.inspect(props.votingGroups)}`);

        this.renderOptions = this.renderOptions.bind(this);
    }

    render() {
        return (
            <div className='form-group'>
                <label>Voting Group:</label>
                <select name='votingGroup' onChange={this.callback}>
                    {this.renderOptions()}
                </select>
            </div>
        );
    }

    renderOptions() {
        let selectedVotingGroup = this.votingGroup;
        return this.votingGroups.map(function(currentVotingGroup, i) {
            console.log(`VotingGroup.renderOptions, currentVotingGroup: ${currentVotingGroup}, looking for '${selectedVotingGroup}'`);
            let optionChecked = (selectedVotingGroup === currentVotingGroup);
            let selectionKey = `selection_${i}`;
            if (optionChecked) {
                console.log(`Option ${currentVotingGroup} is selected`);
                return (
                    <option key={selectionKey} value={currentVotingGroup} selected>{currentVotingGroup}</option>
                );
            }
            else {
                console.log(`Option ${currentVotingGroup} is not selected`);
                return (
                    <option key={selectionKey} value={currentVotingGroup}>{currentVotingGroup}</option>
                );
            }
        });
    }
}