import React from 'react';
import logo from '../images/logo.svg';
import { Container, Navbar, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faList, 
    faPlusSquare, 
    faUpload, 
    faCheckSquare, 
    faUserPlus,
    faUserShield,
    faHome,
    faPeopleArrows
} from "@fortawesome/free-solid-svg-icons";

export default class Header extends React.Component {
    constructor(props, title)
    {
        super(props);
    }
    render()
    {
        return (
            <Navbar bg="white" expand="lg" className="border-bottom my-0 py-0">
                <Container>
                    <Navbar.Brand href="/" className="py-0">
                        <img src={logo} className="App-logo" alt="logo" />
                        <span>Survey</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-nav" />
                    <Navbar.Collapse id="main-nav">
                        {
                            this.props.user && this.props.user.isAdmin ? 
                            <Nav className="me-auto">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>List ballots</Tooltip>}
                                >
                                    <Nav.Link href="/listBallots" className="px-3 py-2 text-left">
                                        <FontAwesomeIcon icon={faList} /> 
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Create ballot</Tooltip>}
                                >
                                    <Nav.Link href="/createBallot" className="px-3 py-2 text-left">
                                        <FontAwesomeIcon icon={faPlusSquare} /> 
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Upload vote code</Tooltip>}
                                >
                                    <Nav.Link href="/uploadVoters" className="px-3 py-2 text-left">
                                        <FontAwesomeIcon icon={faUpload} /> 
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Verify vote</Tooltip>}
                                >
                                    <Nav.Link href="/verifyVote" className="px-3 py-2 text-left">
                                        <FontAwesomeIcon icon={faCheckSquare} /> 
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Register Voter</Tooltip>}
                                >
                                    <Nav.Link href="/registerVoter" className="px-3 py-2 text-left">
                                        <FontAwesomeIcon icon={faUserPlus} /> 
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Add Voting Group</Tooltip>}
                                >
                                    <Nav.Link href="/addVotingGroup" className="px-3 py-2 text-left">
                                        <FontAwesomeIcon icon={faPeopleArrows} /> 
                                    </Nav.Link>
                                </OverlayTrigger>
                            </Nav>
                            : 
                            <Nav className="me-auto"></Nav>
                        }
                        <Nav>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>Community</Tooltip>}
                            >
                                <Nav.Link href={process.env.REACT_APP_FORUM_URL} className="px-3 py-2 text-left" target="_blank">
                                    <FontAwesomeIcon icon={faHome} /> 
                                </Nav.Link>
                            </OverlayTrigger>
                            {
                               this.props.user && this.props.user.isAdmin ? 
                               <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>{this.props.user.username}'s Profile</Tooltip>}
                                >
                                    <Nav.Link href={process.env.REACT_APP_FORUM_URL + "/user/" + this.props.user.username} className="px-3 py-2 text-left" target="_blank">
                                        <FontAwesomeIcon icon={faUserShield} />
                                        <span className="ms-1">{this.props.user.username}</span>
                                    </Nav.Link>
                                </OverlayTrigger> 
                                : null
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}