import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import { arrayMoveMutable } from 'array-move';
import { notify } from '../helpers';
import AuthorBallotQuestion from './authorBallotQuestion';
import VotingGroup from './votingGroup';
import EmailSender from './emailSender';
const util = require('util');

class CloneBallot extends React.Component {
    constructor(props) {
        super(props);

        this.ballotId = props.match.params.ballotId;
        this.state = {
            owner: '',
            precinct: '',
            votingGroup: '',
            emailSender: '',
            startDate: '',
            endDate: '',
            language: '',
            title: '',
            emailTitle: '',
            emailContent: '',
            showVerification: true,
            disclaimer: ['', '', '', ''],
            questions: [],
            votingGroups: [],
            emailSenders: []
        };
        this.initialized = false;

        this.onChangeOwner = this.onChangeOwner.bind(this);
        this.onChangePrecinct = this.onChangePrecinct.bind(this);
        this.onChangeVotingGroup = this.onChangeVotingGroup.bind(this);
        this.onChangeEmailSender = this.onChangeEmailSender.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeEmailTitle = this.onChangeEmailTitle.bind(this);
        this.onChangeEmailContent = this.onChangeEmailContent.bind(this);
        this.onChangeShowVerification = this.onChangeShowVerification.bind(this);
        this.onChangeDisclaimerInternal = this.onChangeDisclaimerInternal.bind(this);
        this.onChangeDisclaimer0 = this.onChangeDisclaimer0.bind(this);
        this.onChangeDisclaimer1 = this.onChangeDisclaimer1.bind(this);
        this.onChangeDisclaimer2 = this.onChangeDisclaimer2.bind(this);
        this.onChangeDisclaimer3 = this.onChangeDisclaimer3.bind(this);
        this.addOneQuestion = this.addOneQuestion.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        trackPromise(axios.get('/votingGroup') 
            .then(response => {
                const resObj = response.data;
                console.log(`CloneBallot, resObj: ${resObj}`);
                this.setState({
                    votingGroups: resObj.votingGroups,
                    emailSenders: resObj.emailSenders
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );

        trackPromise(axios.get('/ballot/' + this.ballotId)
            .then(response => {
                let ballot = response.data;
                this.initialized = true;        // do it before setState
                this.setState({
                    owner: ballot.owner,
                    precinct: ballot.precinct,
                    votingGroup: ballot.votingGroup,
                    startDate: ballot.startDate,
                    endDate: ballot.endDate,
                    language: ballot.language,
                    title: ballot.title,
                    emailTitle: ballot.emailTitle,
                    emailContent: ballot.emailContent,
                    emailSender: ballot.emailSender,
                    showVerification: ballot.showVerification ? true : false,
                    disclaimer: ballot.disclaimer,
                    questions: ballot.questions
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );
    }

    render() {
        console.log(`CloneBallot.render, state: ${util.inspect(this.state)}`);
        if (this.state.votingGroups.length <= 0 || !this.initialized) {
            return(<div></div>);
        }

        return (
            <div>
                <h3>Edit Ballot</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>Owner: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.owner}
                            onChange={this.onChangeOwner}
                            />
                    </div>
                    <div className="form-group">
                        <label>Precinct: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.precinct}
                            onChange={this.onChangePrecinct}
                            />
                    </div>
                    <VotingGroup key="votingGroup_input" votingGroups={this.state.votingGroups} votingGroup={this.state.votingGroup} callback={this.onChangeVotingGroup} />
                    <div className="form-group">
                        <label>Start Date: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.startDate}
                            onChange={this.onChangeStartDate}
                            />
                    </div>
                    <div className="form-group">
                        <label>End Date: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.endDate}
                            onChange={this.onChangeEndDate}
                            />
                    </div>
                    <div className="form-group">
                        <label>Language: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.language}
                            onChange={this.onChangeLanguage}
                            />
                    </div>
                    <div className="form-group">
                        <label>Title: </label>
                        <input type="text"
                            className="form-control"
                            value={this.state.title}
                            onChange={this.onChangeTitle}
                            />
                    </div>
                    <div className="form-group">
                        <label>Email Title: </label>
                        <input type="text"
                            id="EmailTitle"
                            className="form-control"
                            value={this.state.emailTitle}
                            onChange={this.onChangeEmailTitle}
                            />
                    </div>
                    <div className="form-group">
                        <label>Email Content in html: (Use replacements: $VotingUrl$, $VoterFirstName$, $VotingEndTime$, $UnsubscribeLink$)</label>
                        <textarea className="form-control"
                            id="EmailContent"
                            value={this.state.emailContent}
                            onChange={this.onChangeEmailContent}
                            />
                    </div>
                    <EmailSender key="emailSender_input" emailSenders={this.state.emailSenders} emailSender={this.state.emailSender} callback={this.onChangeEmailSender} />
                    <div className="form-group">
                        <input type="checkbox" id="showVerification" name="showVerification" checked={this.state.showVerification} 
                            onChange={this.onChangeShowVerification}/>
                        <label>Show verification code</label>
                    </div>
                    <div className="form-group">
                        <label>Disclaimer (Format: disclaimer1, anchorText, link, disclaimer2): </label>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="text"
                                               id={"disclaimerID_0"}
                                               className="form-control"
                                               value={this.state.disclaimer[0]}
                                               onChange={this.onChangeDisclaimer0}
                                        />
                                    </td>
                                    <td>
                                        <input type="text"
                                               id={"disclaimerID_1"}
                                               className="form-control"
                                               value={this.state.disclaimer[1]}
                                               onChange={this.onChangeDisclaimer1}
                                        />
                                    </td>
                                    <td>
                                        <input type="text"
                                               id={"disclaimerID_2"}
                                               className="form-control"
                                               value={this.state.disclaimer[2]}
                                               onChange={this.onChangeDisclaimer2}
                                        />
                                    </td>
                                    <td>
                                        <input type="text"
                                               id={"disclaimerID_3"}
                                               className="form-control"
                                               value={this.state.disclaimer[3]}
                                               onChange={this.onChangeDisclaimer3}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.renderQuestions()}
                    <div className="form-group">
                        <button className="btn btn-light border" onClick={this.addOneQuestion}>
                            <FontAwesomeIcon className="text-dark hand" icon={faPlus} />
                            <span className="ms-1">Add question</span>
                        </button> 
                    </div>
                    <br />
                    <div className="form-group">
                        <input type="submit" value="Upload Ballot" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    addOneQuestion(e)
    {
        let question = {
            statement: '',
            statementWithLink: ['', '', '', ''],
            mostElectionNumber: '',
            options: [],
            writeIn: 0
        };

        let copiedQuestions = this.state.questions;
        copiedQuestions.push(question);

        this.setState({
            questions: copiedQuestions
        });
        
        e && e.preventDefault();
        return false;
    }

    renderQuestions()
    {
        let removeQuestion = this.removeQuestion.bind(this);
        let moveQuestionUp = this.moveQuestionUp.bind(this);
        let moveQuestionDown = this.moveQuestionDown.bind(this);
        let updateQuestion = this.updateQuestion.bind(this);
        return this.state.questions.map(function(currentQuestion, i) {
            return (
                // key is not appeared in props. Using index.
                // key is used to cache AuthorBallotQuestion. Make it different when changing question position 
                <AuthorBallotQuestion key={JSON.stringify(currentQuestion) + i} index={i} question={currentQuestion}
                    removeQuestionCallback={removeQuestion}
                    moveQuestionUpCallback={moveQuestionUp}
                    moveQuestionDownCallback={moveQuestionDown}
                    updateQuestionCallback={updateQuestion} />
            );
        });
    }

    updateQuestion(i, updatedQuestion)
    {
        let modifiedQuestions = Object.assign([], this.state.questions);  // Use shadow copy to prevent undesired state update
        if (0 <= i && i < modifiedQuestions.length)
        {
            modifiedQuestions[i] = updatedQuestion;
        }

        this.setState({
            questions: modifiedQuestions
        });
    }

    removeQuestion(i)
    {
        let modifiedQuestions = this.state.questions;
        if (0 <= i && i < modifiedQuestions.length)
        {
            modifiedQuestions.splice(i, 1);
        }

        this.setState({
            questions: modifiedQuestions
        });
    }

    moveQuestionUp(i)
    {
        let modifiedQuestions = this.state.questions;
        console.log(`moveQuestionUp before move: i: ${i}, modifiedQuestions: ${util.inspect(modifiedQuestions)}`);
        if (0 < i && i < modifiedQuestions.length)
        {
            arrayMoveMutable(modifiedQuestions, i, i - 1);
            console.log(`moveQuestionUp after move: i: ${i}, modifiedQuestions: ${util.inspect(modifiedQuestions)}`);

            this.setState({
                questions: modifiedQuestions
            });
        }
    }

    moveQuestionDown(i)
    {
        let modifiedQuestions = this.state.questions;
        console.log(`moveQuestionDown before move: i: ${i}, modifiedQuestions: ${util.inspect(modifiedQuestions)}`);
        if (0 <= i && i < modifiedQuestions.length-1)
        {
            arrayMoveMutable(modifiedQuestions, i, i + 1);
            console.log(`moveQuestionDown after move: i: ${i}, modifiedQuestions: ${util.inspect(modifiedQuestions)}`);

            this.setState({
                questions: modifiedQuestions
            });
        }
    }

    onChangeTitle(e)
    {
        this.setState({
            title: e.target.value
        });
    }

    onChangeEmailTitle(e)
    {
        this.setState({
            emailTitle: e.target.value
        });
    }

    onChangeEmailContent(e)
    {
        this.setState({
            emailContent: e.target.value
        });
    }

    onChangeEmailSender(e)
    {
        this.setState({
            emailSender: e.target.value
        });
    }

    onChangeShowVerification(e)
    {
        this.setState({
            showVerification: !this.state.showVerification
        });
    }

    onChangeDisclaimerInternal(index, e)
    {
        let disclaimerTemp = this.state.disclaimer;
        disclaimerTemp[index] = e.target.value;
        this.setState({
            disclaimer: disclaimerTemp
        });
    }

    onChangeDisclaimer0(e)
    {
        this.onChangeDisclaimerInternal(0, e);
    }

    onChangeDisclaimer1(e)
    {
        this.onChangeDisclaimerInternal(1, e);
    }

    onChangeDisclaimer2(e)
    {
        this.onChangeDisclaimerInternal(2, e);
    }

    onChangeDisclaimer3(e)
    {
        this.onChangeDisclaimerInternal(3, e);
    }

    onChangeLanguage(e)
    {
        this.setState({
            language: e.target.value
        });
    }

    onChangeEndDate(e)
    {
        this.setState({
            endDate: e.target.value
        });
    }

    onChangeStartDate(e)
    {
        this.setState({
            startDate: e.target.value
        });
    }

    onChangeVotingGroup(e)
    {
        console.log('EditBallot.onChangeVotingGroup: '+ e.target.value);
        this.setState({
            votingGroup: e.target.value
        });
    }

    onChangePrecinct(e)
    {
        this.setState({
            precinct: e.target.value
        });
    }

    onChangeOwner(e)
    {
        this.setState({
            owner: e.target.value
        });
    }

    async onSubmit(e)
    {
        e.preventDefault();

        const newBallot = {
            owner: this.state.owner,
            precinct: this.state.precinct,
            votingGroup: this.state.votingGroup,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            language: this.state.language,
            title: this.state.title,
            emailTitle: this.state.emailTitle,
            emailContent: this.state.emailContent,
            emailSender: this.state.emailSender,
            showVerification: this.state.showVerification,
            disclaimer: this.state.disclaimer,
            questions: this.state.questions
        };
        console.log('onSubmit, newBallot: ' + util.inspect(newBallot));

        await trackPromise(axios.post('/ballot', newBallot).catch(e => notify(e)))

        this.setState({
            owner: '',
            precinct: '',
            votingGroup: '',
            startDate: '',
            endDate: '',
            language: '',
            title: '',
            emailTitle: '',
            emailContent: '',
            emailSender: '',
            showVerification: true,
            disclaimer: ['', '', '', ''],
            questions: []
        });
    }
}

export default withRouter(CloneBallot);