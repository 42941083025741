import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
import util from 'util';

function containsObject(obj, list) {
    var x;
    for (x in list) {
        if (list.hasOwnProperty(x) && list[x] === obj) {
            return true;
        }
    }

    return false;
}

class SingleSelectionQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.question = props.question;
        this.index = props.index;
        this.writeIn = props.writeIn;
        this.selection = props.selection;
        this.optionName = 'Question' + this.index;

        this.questionCallback = props.questionCallback;
        this.onChangeWriteIn = this.onChangeWriteIn.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
    }

    render() {
        return (
            <div className="form-group">
                <label>{this.question.statementWithLink[0]}<a href={this.question.statementWithLink[2]} target="_blank" rel="noopener noreferrer">{this.question.statementWithLink[1]}</a>{this.question.statementWithLink[3]}</label>
                <table className='table table-striped' style={{marginTop: 20}}>
                    <colgroup>
                        <col span="1" style={{width: "5%"}}/>
                        <col span="1" style={{width: "95%"}}/>
                    </colgroup>
                    {this.renderOptions()}
                    {this.renderWriteIn()}
                </table>
            </div>
        );
    }

    renderOptions() {
        let selection = this.selection;
        let optionName = this.optionName;
        return this.question.options.map(function(currentOption, i) {
            let optionChecked = (selection === currentOption);
            return (
                <tr key={i}>
                    <td>
                        <input type="radio" name={optionName} id={optionName + ',' + currentOption} value={currentOption} checked={optionChecked}
                            readOnly="readonly" />
                    </td>
                    <td>
                        <label>{currentOption}</label>
                    </td>
                </tr>
            );
        });
    }

    renderWriteIn() {
        if (this.question.writeIn !== 1) {
            return (
                <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
            );
        }

        let writeInValue = 'WriteIn';
        let optionChecked = (this.selection === writeInValue)
        return (
            <tr>
                <td>
                    <input type="radio" name={this.optionName} id={this.optionName + ',writeIn'} value={writeInValue} checked={optionChecked}
                        readOnly="readonly" />
                </td>
                <td>
                    <input type="text" className="form-control" value={this.writeIn}  readOnly="readonly" />
                </td>
            </tr>
        );
    }

    onChangeWriteIn(e) {
        this.writeIn = e.target.value;

        this.questionCallback(this.index, this.question.statement, [this.selection], [this.writeIn]);
    }

    onChangeRadio(e) {
        this.selection = e.target.value;

        this.questionCallback(this.index, this.question.statement, [this.selection], [this.writeIn]);
    }
}

class MultiWriteIn extends React.Component {
    constructor(props) {
        super(props);

        this.index = props.index;
        this.optionName = props.optionName;
        this.selectionName = 'writeIn' + this.index;
        this.writeIn = props.writeIn;
        this.selections = props.selections;

        this.selected = containsObject(this.selectionName, this.selections);

        this.onChangeCheckboxCallback = props.onChangeCheckboxCallback;
        this.onChangeWriteInCallback = props.onChangeWriteInCallback;
        this.onChangeWriteIn = this.onChangeWriteIn.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    render() {
        return (
            <tr>
                <td>
                    <input type="checkbox" name={this.optionName} id={this.optionName + ',' + this.selectionName} value={this.selectionName} checked={this.selected}
                        readOnly="readonly" />
                </td>
                <td>
                    <input type="text" className="form-control" value={this.writeIn} readOnly="readonly" />
                </td>
            </tr>
        );
    }

    onChangeWriteIn(e) {
        this.writeIn = e.target.value;

        this.onChangeWriteInCallback(this.index, this.writeIn);
    }

    onChangeCheckbox(e) {
        this.selected = !this.selected;
        this.onChangeCheckboxCallback(e);
    }
}

class MultiSelectionQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.question = props.question;
        this.index = props.index;
        this.selections = props.selections;
        this.writeIns = props.writeIns;
        this.optionName = 'Question' + this.index;
        this.maxSelection = props.maxSelection;
        this.maxWriteIn = props.maxWriteIn;
        this.hideComment = props.hideComment;

        if (this.selections === undefined) {
            this.selections = [];
        }

        if (this.writeIns === undefined) {
            this.writeIns = [];
            for (let i = 0; i < this.maxWriteIn; i++) {
                this.writeIns.push('');
            }
        }

        this.questionCallback = props.questionCallback;
        this.OnChangeCheckbox = this.OnChangeCheckbox.bind(this);
        this.onChangeWriteInCallback = this.onChangeWriteInCallback.bind(this);
    }

    render() {
        return (
            <div className='form-group'>
                <label>{this.question.statementWithLink[0]}<a href={this.question.statementWithLink[2]} target="_blank" rel="noopener noreferrer">{this.question.statementWithLink[1]}</a>{this.question.statementWithLink[3]}</label>
                <table className='table table-striped' style={{marginTop: 20}}>
                    <colgroup>
                        <col span="1" style={{width: "5%"}}/>
                        <col span="1" style={{width: "95%"}}/>
                    </colgroup>
                    {this.renderOptions()}
                    {this.renderWriteIns()}
                </table>
            </div>
        );
    }

    renderOptions() {
        let selections = this.selections;
        let optionName = this.optionName;
        return this.question.options.map(function(currentOption, i) {
            let optionChecked = false;
            if (selections !== undefined) {
                optionChecked = containsObject(currentOption, selections);
            }

            return (
                <tr key={i}>
                    <td>
                        <input type="checkbox" name={optionName} id={optionName + ',' + currentOption} value={currentOption} checked={optionChecked}
                            readOnly="readonly" />
                    </td>
                    <td>
                        <label>{currentOption}</label>
                    </td>
                </tr>
            );
        });
    }

    renderWriteIns() {
        let selections = this.selections;
        let optionName = this.optionName;
        let onChangeCheckbox = this.OnChangeCheckbox;
        let onChangeWriteInCallback = this.onChangeWriteInCallback;
        let dummyOnChangeWriteIn = this.dummyOnChangeWriteIn;
        let hideComment = this.hideComment;
        return this.writeIns.map(function(currentWriteIn, i) {
            if (hideComment && currentWriteIn.trim().length > 0) {
                currentWriteIn = 'Has comments';
            }
            return (
                <MultiWriteIn key={i}
                    index={i}
                    optionName={optionName}
                    writeIn={currentWriteIn}
                    selections={selections}
                    onChangeCheckboxCallback={onChangeCheckbox}
                    onChangeWriteInCallback={onChangeWriteInCallback}
                    onChange={dummyOnChangeWriteIn}
                />
            );
        });
    }

    OnChangeCheckbox(e) {
        let value = e.target.value;
        if (containsObject(value, this.selections)) {
            this.selections = this.selections.filter(function(item) {
                return item !== value;
            });
        }
        else {
            if (this.selections.length < this.maxSelection) {
                this.selections.push(value);
            }
        }
        this.questionCallback(this.index, this.question.statement, this.selections, this.writeIns);
    }

    onChangeWriteInCallback(index, writeIn) {
        this.writeIns[index] = writeIn;

        this.questionCallback(this.index, this.question.statement, this.selections, this.writeIns);
    }

    dummyOnChangeWriteIn(e) {
        // Do nothing
    }
}

class QuestionResult extends React.Component {
    constructor(props) {
        super(props);

        this.mostElectionNumber = props.mostElectionNumber;
        this.questionSum = props.questionSum;
        this.ballotOptions = props.ballotOptions;
        this.index = props.index;

        this.calculateWinnerThreshold = this.calculateWinnerThreshold.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.renderWriteIns = this.renderWriteIns.bind(this);
    }

    render() {
        let winnerNumber = this.calculateWinnerThreshold();
        return (
            <div className='form-group'>
                <label>{this.questionSum.statementWithLink[0]}<a href={this.questionSum.statementWithLink[2]} target="_blank" rel="noopener noreferrer">{this.questionSum.statementWithLink[1]}</a>{this.questionSum.statementWithLink[3]}</label>
                <table className='table table-striped' style={{marginTop: 20}}>
                    <colgroup>
                        <col span="1" style={{width: "5%"}}/>
                        <col span="1" style={{width: "87%"}}/>
                        <col span="1" style={{width: "8"}}/>
                    </colgroup>
                    <tbody>
                        {this.renderOptions(winnerNumber)}
                        {this.renderWriteIns(winnerNumber)}
                    </tbody>
                </table>
            </div>
        );
    }

    renderOptions(winnerNumber) {
        const selectionsMap = this.questionSum.selections;
        const verifiedSelectionsMap = this.questionSum.verifiedSelections;
        if (selectionsMap === undefined && verifiedSelectionsMap === undefined)
            return;

        if (this.ballotOptions) {
            return this.ballotOptions.map(function(name, index) {
                let numVotes = 0;
                let verifiedNumVotes = 0;
                if (selectionsMap !== undefined) {
                   if (selectionsMap[name] !== undefined) {
                        numVotes = selectionsMap[name];
                    }
                }
                if (verifiedSelectionsMap !== undefined) {
                    if (verifiedSelectionsMap[name] !== undefined) {
                        verifiedNumVotes = verifiedSelectionsMap[name];
                    }
                }

                let optionChecked = (verifiedNumVotes >= winnerNumber);

                return (
                    <tr key={index}>
                        <td>
                            <input type="checkbox" name={name} value={name} checked={optionChecked} readOnly="readonly" />
                        </td>
                        <td>
                            <label>{name}</label>
                        </td>
                        <td>
                            <label>{verifiedNumVotes} ({numVotes})</label>
                        </td>
                    </tr>
                );
            })
        }
    }

    renderWriteIns(winnerNumber) {
        const writeInsMap = this.questionSum.writeIns;
        const verifiedWriteIns = this.questionSum.verifiedWriteIns;
        if (writeInsMap === undefined && verifiedWriteIns === undefined)
            return;

        const name="other";
        let numVotes = 0;
        let verifiedNumVotes = 0;
        if (name in writeInsMap) {
            if (writeInsMap !== undefined) {
                numVotes = writeInsMap[name];
            }
            if (verifiedWriteIns !== undefined) {
                verifiedNumVotes = verifiedWriteIns[name];
            }
            if (numVotes > 0) {
                return (
                    <tr key={0}>
                        <td>
                            <input type="checkbox" name="writeIn" value="other" readOnly="readonly" />
                        </td>
                        <td>
                            <label>{name}</label>
                        </td>
                        <td>
                            <label>{verifiedNumVotes} ({numVotes})</label>
                        </td>
                    </tr>
                );
            }
        }
    }

    calculateWinnerThreshold() {
        console.log(`QuestionResult.calculateWinnerThreshold, ${util.inspect(this.questionSum)}`);
        let numbers = [];   // all the voting numbers for each option
        for (const name in this.questionSum.verifiedSelections) {
            numbers.push(this.questionSum.verifiedSelections[name]);
        }

        for (const w in this.verifiedWriteIns) {
            numbers.push(this.questionSum.verifiedSelections[w]);
        }

        numbers.sort((a, b) => b - a);  // default sort is string sort. Use number reverse sort.

        if (numbers.length >= this.mostElectionNumber) {
            return numbers[this.mostElectionNumber - 1];
        }

        return numbers.pop();   // The last valid number
    }
}

class ElectionResults extends React.Component
{
    constructor(props) {
        super(props);

        let paramStr = props.location.search;
        let position = paramStr.search("noc=true");
        this.hideComment = position >= 0;

        this.ballotId = props.match.params.ballotId;
        this.verificationSubmited = false;
        this.state = {
            errorMessage: '',
            ballot: {},
            numVotes: 0,
            numVerifiedVotes: 0,
            votesSum: {},
            listVotes: []
        };

        this.renderQuestions = this.renderQuestions.bind(this);
        this.questionCallback = this.questionCallback.bind(this);
        this.onSubmitBallot = this.onSubmitBallot.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.renderIndividualVotes = this.renderIndividualVotes.bind(this);
        this.renderVoteQuestions = this.renderVoteQuestions.bind(this);
    }

    componentDidMount() {
        trackPromise(axios.get('/ballotResult/' + this.ballotId)
            .then(response => {
                let ballotResult = response.data;
                console.log(`ElectionResults.componentDidMount, ballotResult: ${util.inspect(ballotResult)}`);
                this.setState({
                    ballot: ballotResult.ballot,
                    numVotes: ballotResult.numVotes,
                    numVerifiedVotes: ballotResult.numVerifiedVotes,
                    votesSum: ballotResult.votesSum,
                    listVotes: ballotResult.listVotes
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );
    }

    render() {
        return (
            <div>
                <h3>{this.state.ballot.title}</h3>
                <h6>From {this.state.ballot.startDate} to {this.state.ballot.endDate}</h6>
                <h4>Total Results:</h4>
                <h6>Verified voters: {this.state.numVerifiedVotes} (Total: {this.state.numVotes})</h6>
                <form onSubmit={this.onSubmitBallot}>
                    {this.renderQuestions()}
                </form>
                <h4>Individual ballots:</h4>
                {this.renderIndividualVotes()}
            </div>
        );
    }

    renderQuestions() {
        if (this.state.ballot.questions === undefined)
            return;

        let votesSum = this.state.votesSum;
        return this.state.ballot.questions.map(function(currentQuestion, i) {
            let questionSum = votesSum['prop_' + i];
            return (
                <QuestionResult key={i} index={i} questionSum={questionSum} ballotOptions={currentQuestion.options}
                    mostElectionNumber={currentQuestion.mostElectionNumber} />
            );
        });
    }

    renderIndividualVotes() {
        if (this.state.listVotes) {
            let renderVoteQuestions = this.renderVoteQuestions;
            return this.state.listVotes.map(function(vote, index) {
                let verifiedVoter = '';
                if (vote.verifiedVoter) {
                    verifiedVoter = ' (Verified voter)';
                }
                return (
                    <div>
                        <hr />
                        <h6>Verification code: {vote['verificationCode']}{verifiedVoter}</h6>
                        <form>
                            {renderVoteQuestions(vote)}
                        </form>
                    </div>
                );
            });
        }
    }

    renderVoteQuestions(vote) {
        let elections = JSON.parse(vote.rawVotes);
        let hideComment = this.hideComment;
        return this.state.ballot.questions.map(function(currentQuestion, i) {
            if (currentQuestion.mostElectionNumber < 1) {
                return (<div/>);        // We use questions without answer as introduction to the survey. Skip for individual results.
            }
            if (elections[i] === undefined) {
                return (<div/>);        // in case we add one more question for testing, previous votes can still count properly
            }
            if (currentQuestion.mostElectionNumber <= 1) {
                let currentWriteIn = '';
                if ('writeIns' in elections[i]) {
                    currentWriteIn = elections[i].writeIns[0].trim();

                    if (hideComment) {
                        if (currentWriteIn.length > 0) {
                            currentWriteIn = 'Has comments';
                        }
                    }
                }
                let currentSelection = '';
                if ('selections' in elections[i]) {
                    currentSelection = elections[i].selections[0];
                }

                return (
                    <SingleSelectionQuestion key={i} index={i} question={currentQuestion} writeIn={currentWriteIn} selection={currentSelection} />
                );
            }

            return (
                <MultiSelectionQuestion key={i} index={i}
                    question={currentQuestion} writeIns={elections[i].writeIns} 
                    selections={elections[i].selections}
                    maxSelection={currentQuestion.mostElectionNumber}
                    maxWriteIn={currentQuestion.writeIn}
                    hideComment={hideComment} />
            );
        });
    }

    questionCallback(index, statement, selections, writeIns) {
        let modifiedElections = this.state.elections;
        let election = modifiedElections[index];
        election.statement = statement;
        election.selections = selections;
        election.writeIns = writeIns;
        this.setState({
            elections: modifiedElections
        });
    }

    onSubmitBallot() {}
}

export default withRouter(ElectionResults);