import React from 'react';
import { Alert } from 'react-bootstrap';

export default class Header extends React.Component {
    constructor(props, title)
    {
        super(props);
    }
    render()
    {
        return (
            <Alert variant="danger">
                You don't have access to this page. 
                If you're an admin, go back to the <a href={process.env.REACT_APP_FORUM_URL + "/login"}>main forum</a>, 
                login, then come back here.
            </Alert>
        );
    }
}