import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTrash, 
} from "@fortawesome/free-solid-svg-icons";
import { notify } from '../helpers';
const util = require('util');

class BallotSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ballot: props.ballot
        };

        console.log(`BallotSummary.ctor, ballot: ${util.inspect(props.ballot)}`);

        this.removeBallotCallback = props.removeBallotCallback;
        this.activateBallotCallback = props.activateBallotCallback;
        
        this.onClickRemove = this.onClickRemove.bind(this);
        this.onClickActivate = this.onClickActivate.bind(this);
    }

    render() {
        return (
            <tr>
                <td>{this.state.ballot.id}</td>
                <td>{this.state.ballot.votingGroup}</td>
                <td>{this.state.ballot.title}</td>
                <td>{this.state.ballot.language}</td>
                <td>
                    <Link to={'/editBallot/' + this.state.ballot.id}>Edit</Link>
                </td>
                <td>
                    <Link to={'/optin/' + this.state.ballot.id}>Opt In</Link>
                </td>
                <td>
                    <Link to={'/cloneBallot/' + this.state.ballot.id}>Clone</Link>
                </td>
                <td>
                    <Link to={'/votingResults/' + this.state.ballot.id + '?noc=true'}>Results</Link>
                </td>
                <td>
                    <Link to={'/listVoters/' + this.state.ballot.id}>List Voters</Link>
                </td>
                <td>
                    <button className="btn btn-sm btn-danger" onClick={this.onClickRemove}>
                        <FontAwesomeIcon icon={faTrash} />
                        <span className="ms-1">Remove</span>
                    </button>
                </td>
            </tr>
        )
    }

    async onClickActivate(e) {
        trackPromise(axios.get('/activateUsersForBallot/' + this.state.ballot.id).catch(e => notify(e)))
        this.activateBallotCallback && this.activateBallotCallback(this.state.ballot.id)
    }
    
    async onClickRemove(e) {
        //await trackPromise(axios.delete('/ballot/' + this.state.ballot.id).catch(e => notify(e)))
        //this.removeBallotCallback && this.removeBallotCallback(this.state.ballot.id)
    }
}

export default withRouter(BallotSummary);   // need withRouter to make this.props.history work