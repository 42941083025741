import React from 'react';
import { Spinner } from 'react-bootstrap';

export default class Header extends React.Component {
    constructor(props, title)
    {
        super(props);
    }
    render()
    {
        const style = {
            display: 'flex',
			justifyContent: 'center',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			alignItems: 'center',
			zIndex: '1041',
            background: 'rgba(255, 255, 255, 0.5)',
			backdropFilter: 'blur(10px)'
        }
        return (
            <div style={style} className="spinner-cover spinner-backdrop">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
}