import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes, faPlus
} from "@fortawesome/free-solid-svg-icons";

// function curry(func)
// {
//     return function curried(...args)
//     {
//         if (args.length >= func.length)
//         {
//             return func.apply(this, args);
//         }
//         else
//         {
//             return function(...args2) {
//                 return curried.apply(this, args.concat(args2));
//             }
//         }
//     };
// }

class AuthorOneOption extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.option = props.option;
        this.updateCallback = props.updateCallback;
        this.removeCallback = props.removeCallback;
        this.key = props.index;

        this.onChangeOption = this.onChangeOption.bind(this);
        this.removeOption = this.removeOption.bind(this);
    }

    render() {
        return (
            <div className="row align-items-center mb-2 pb-2 border-bottom">
                <div className="col-auto">
                    <label className="col-auto">Option {this.key + 1}: </label>
                </div>
                <div className="col-auto">
                    <input type="text"
                        className="form-control"
                        value={this.state.option}
                        onChange={this.onChangeOption}
                    />
                </div>
                <div className="col-auto">
                    <FontAwesomeIcon className="text-danger hand" title="Remove option" icon={faTimes} onClick={this.removeOption}/>
                </div>
            </div>
        );
    }

    removeOption() {
        this.removeCallback(this.key);
    }

    onChangeOption(e) {
        this.setState({
            option: e.target.value
        },
        () => this.updateCallback(this.key, this.state.option));
    }
}

export default class AuthorQuestionOptions extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
        this.state.options = props.options;
        this.updateOptionsCallback = props.updateOptionsCallback;

        this.addOneOption = this.addOneOption.bind(this);
    }

    render()
    {
        return (
            <div className="form-group border-top my-4 pt-4">
                <h6>Options:</h6>
                {this.renderOptions()}
                <div className="my-2">
                    <button className="btn btn-sm btn-light border" onClick={this.addOneOption}>
                        <FontAwesomeIcon className="text-dark hand" icon={faPlus} />
                        <span className="ms-1">Add option</span>
                    </button> 
                </div>
            </div>
        );
    }

    updateOption(index, value) {
        let modifiedOptions = this.state.options;
        if (0 <= index && index < this.state.options.length) {
            modifiedOptions[index] = value;
        }

        this.updateOptions(modifiedOptions);
    }

    removeOption(index) {
        let modifiedOptions = this.state.options;
        if (0 <= index && index < this.state.options.length) {
            modifiedOptions.splice(index, 1);
        }

        this.updateOptions(modifiedOptions);
    }

    addOneOption(e)
    {
        let modifiedOptions = this.state.options;
        modifiedOptions.push("");
        this.updateOptions(modifiedOptions);
        e && e.preventDefault();
        return false;
    }

    updateOptions(modifiedOptions)
    {
        this.setState({
            options: modifiedOptions
        },
        () => this.updateOptionsCallback(this.state.options));
    }

    renderOptions() {
        let updateOption = this.updateOption.bind(this);
        let removeOption = this.removeOption.bind(this);
        return this.state.options.map(function(currentOption, i) {
            return (
                <AuthorOneOption option={currentOption} key={i} index={i}
                    updateCallback={updateOption} removeCallback={removeOption} />
            );
        });
    }
}