import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'dotenv-defaults/config'

import './css/App.css';

import AppHeader from './components/appHeader';
import GlobalSpinner from './components/globalSpinner';
import Spinner from './components/spinner';

import NoAccess from './components/noAccess';

import CreateBallot from './components/createBallot';
import UploadVotingCode from './components/uploadVotingCode';
import UploadVoters from './components/uploadVoters';
import ListBallots from './components/listBallots';
import EditBallot from './components/editBallot';
import CloneBallot from './components/cloneBallot';
import Vote from './components/vote';
import VerifyVote from './components/verifyVote';
import RegisterVoter from './components/registerVoter';
import ElectionResults from './components/electionResults';
import AddVotingGroup from './components/addVotingGroup';
import RequestToVote from './components/requestToVote';
import ListVoters from './components/listVoters';
import AddEmailSender from './components/addEmailSender';

class App extends React.Component {
  constructor(props) {
      super(props);
      this.state = {};
      this.state.user = null;
      this.state.ready = false;
  }
  render () {
    return (
      <Router>
        <div className="App">
          <AppHeader user={this.state.user} />
        </div>
        {
          this.state.ready ?
            <Container className="pt-4">
              <GlobalSpinner />
              <ToastContainer />
              <Switch>
                <Route path='/vote/:ballotId/:votingCode' component={Vote} />
                <Route path='/optin/:ballotId' component={RequestToVote} />
                <Route path='/votingResults/:ballotId'>
                  {this.state?.user?.isAdmin ? <ElectionResults /> : <NoAccess />}
                </Route>
                <Route exact path='/createBallot'>
                  {this.state?.user?.isAdmin ? <CreateBallot /> : <NoAccess />}
                </Route>
                <Route exact path='/editBallot/:ballotId'>
                  {this.state?.user?.isAdmin ? <EditBallot /> : <NoAccess />}
                </Route>
                <Route exact path='/cloneBallot/:ballotId'>
                  {this.state?.user?.isAdmin ? <CloneBallot /> : <NoAccess />}
                </Route>
                <Route exact path='/uploadVoters'>
                  {this.state?.user?.isAdmin ? <UploadVoters /> : <NoAccess />}
                </Route>
                <Route exact path='/verifyVote'>
                  {this.state?.user?.isAdmin ? <VerifyVote /> : <NoAccess />}
                </Route>
                <Route exact path='/registerVoter'>
                  {this.state?.user?.isAdmin ? <RegisterVoter /> : <NoAccess />}
                </Route>
                <Route exact path='/listBallots'>
                  {this.state?.user?.isAdmin ? <ListBallots /> : <NoAccess />}
                </Route>
                <Route exact path='/addVotingGroup'>
                  {this.state?.user?.isAdmin ? <AddVotingGroup /> : <NoAccess />}
                </Route>
                <Route exact path='/listVoters/:ballotId'>
                  {this.state?.user?.isAdmin ? <ListVoters /> : <NoAccess />}
                </Route>
                <Route exact path='/addEmailSender'>
                  {this.state?.user?.isAdmin ? <AddEmailSender /> : <NoAccess />}
                </Route>
                <Route exact path='/'>
                  {this.state?.user?.isAdmin ? <ListBallots /> : <NoAccess />}
                </Route>
              </Switch>
          </Container>
          : <Spinner />
        }                                  
      </Router>
    );
  }
  async componentDidMount () {
    trackPromise(axios.get('/user')
      .then(res => {
        const user = res?.data
        if (user) {
          user.isAdmin = !!user.groups.find(group => group?.name === 'administrators')
        }
        this.setState({ user: user, ready: true })
      })
      .catch(e => { 
        this.setState({ user: null, ready: true })
        console.error(e) 
      }))
  }
}

export default App;
