import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
const util = require('util');

export default class UploadVotingCode extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {};
        this.state.votingCodeList = '';

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeVotingCodeList = this.onChangeVotingCodeList.bind(this);
    }

    render()
    {
        return (
            <div>
                <h3>Upload Voting Code</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>Voting code in Json format: </label>
                        <textarea className="form-control"
                            value={this.state.votingCodeList}
                            onChange={this.onChangeVotingCodeList}
                            />
                    </div>
                    <div className="form-group mt-4">
                        <input type="submit" value="Upload Voting Code" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    onChangeVotingCodeList(e)
    {
        this.setState({
            votingCodeList: e.target.value
        });
    }

    onSubmit(e)
    {
        let votingCodeList = JSON.parse(this.state.votingCodeList);     // Must use double quotes. Single quotes will crash.

        e.preventDefault();

        trackPromise(axios.post('/voters', votingCodeList)
            .then(res => {
                this.setState({
                    votingCodeList: ''
                });
            })
            .catch(error => {
                console.error('UploadVotingCode.onSubmit, error: ' + util.inspect(error));
                notify(error);
            })
        );
    }
}