import { usePromiseTracker } from "react-promise-tracker";
import Spinner from './spinner';

const GlobalSpinner = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        <div>
        {
            (promiseInProgress === true) ?
                <Spinner />
            :
                null
        }
        </div>
  )
};

export default GlobalSpinner