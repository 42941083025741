import React from 'react';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
const util = require('util');

export default class AddVotingGroup extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            votingGroup: '',
            comment: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeVotingGroup = this.onChangeVotingGroup.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
    }

    render()
    {
        console.log('AddVotingGroup.render');
        return (
            <div>
                <h3>Add one VotingGroup</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>Voting group name: </label>
                        <input className="form-control"
                            id="votingGroup"
                            value={this.state.votingGroup}
                            onChange={this.onChangeVotingGroup}
                            />
                    </div>
                    <div className="form-group">
                        <label>Comment: </label>
                        <input className="form-control"
                            id="comment"
                            value={this.state.comment}
                            onChange={this.onChangeComment}
                            />
                    </div>
                    <div className="form-group mt-4">
                        <input type="submit" value="Add Voting Group" className="btn btn-primary" />
                    </div>
                </form>
            </div>
        );
    }

    onChangeVotingGroup(e)
    {
        this.setState({
            votingGroup: e.target.value
        });
    }

    onChangeComment(e)
    {
        this.setState({
            comment: e.target.value
        });
    }

    onSubmit(e)
    {
        e.preventDefault();

        let votingGroupInfo = {
            votingGroup: this.state.votingGroup,
            comment: this.state.comment
        };

        trackPromise(axios.post('/votingGroup', votingGroupInfo)
            .then(res => {
                this.setState({
                    votingGroup: '',
                    comment: ''
                });
            })
            .catch(error => {
                console.error('AddVotingGroup.onSubmit, error: ' + util.inspect(error));
                notify(error);
            })
        );
    }
}