import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { notify } from '../helpers';
const util = require('util');


class ListVoters extends React.Component {
    constructor(props) {
        super(props);

        this.ballotId = props.match.params.ballotId;
        this.state = {voters: []};

        this.listVoters = this.listVoters.bind(this);
    }

    componentDidMount() {
        trackPromise(axios.get('/votersForBallot/' + this.ballotId)
            .then(response => {
                this.setState({
                    voters: response.data
                });
            })
            .catch(error => {
                console.error(error);
                notify(error);
            })
        );
    }

    listVoters() {
        return this.state.voters.map(function(voter) {
            return (
                <tr key={voter.voteCode}>
                    <td>{voter.voterEmail}</td>
                    <td>{voter.voterFirstName}</td>
                    <td>{voter.voteCode}</td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <h3>Voters List</h3>
                <table className='table table-striped table-responsive' >
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>FirstName</th>
                            <th>VoteCode</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.listVoters() }
                    </tbody>
                </table>
            </div>                
        );
    }
}

export default withRouter(ListVoters);