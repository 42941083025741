import React from 'react';
const util = require('util');

// Shared: EmailSender selection
export default class EmailSender extends React.Component {
    constructor(props) {
        super(props);

        this.emailSenders = props.emailSenders;
        this.emailSender = props.emailSender;   // emailSender selected
        this.callback = props.callback;

        console.log(`EmailSender.ctor, emailSender: ${this.emailSender}, emailSenders: ${util.inspect(props.emailSenders)}`);

        this.renderOptions = this.renderOptions.bind(this);
    }

    render() {
        return (
            <div className='form-group'>
                <label>Email Sender:</label>
                <select name='emailSender' onChange={this.callback}>
                    {this.renderOptions()}
                </select>
            </div>
        );
    }

    renderOptions() {
        let selectedEmailSender = this.emailSender;
        return this.emailSenders.map(function(currentEmailSender, i) {
            console.log(`EmailSender.renderOptions, currentEmailSender: ${currentEmailSender}, looking for '${selectedEmailSender}'`);
            let optionChecked = (selectedEmailSender === currentEmailSender);
            let selectionKey = `selection_${i}`;
            if (optionChecked) {
                console.log(`Option ${currentEmailSender} is selected`);
                return (
                    <option key={selectionKey} value={currentEmailSender} selected>{currentEmailSender}</option>
                );
            }
            else {
                console.log(`Option ${currentEmailSender} is not selected`);
                return (
                    <option key={selectionKey} value={currentEmailSender}>{currentEmailSender}</option>
                );
            }
        });
    }
}